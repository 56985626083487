import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import "../components/layout.css"
import fullLogo from "../images/english-title-complete.png"
import fullIrish from "../images/irish-title-complete.png"
import introAudio from "../images/new-intro-audio.wav"
import sixteen from "../images/16plus.svg"
import play from "../images/sound.svg"
import mute from "../images/mute.svg"


const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
      <>
        <div class="home-bg">
          <header>
            <div className="title-fade">
                <img className="logo" className="first" id="english-logo" src={fullLogo} alt="Irish Imbas Dark Dawn" />
                <img className="logo" className="second" id="irish-logo" src={fullIrish} alt="Irish Imbas Dark Dawn Irish logo" />
            </div>
            <div className="controls">
              <div onClick={toggleAudio} className="audio-controls">
                  <img id="play-button" src={play} alt="play button" />
                  <img id="mute-button" src={mute} alt="mute button" />
              </div>
              <img src={sixteen} className="sixteen-plus"  alt="This game is recommended for users aged sixteen and over"/>
              <audio id="introAudio" src={introAudio} type="audio/mpeg" autoPlay loop />
            </div>
        </header>
            <main>
            <Helmet title={frontmatter.title}  bodyAttributes={{
            class: 'home'
        }} />
            <a href="/game/introduction"><button>Start in English</button></a>
            <a href="/game/réamhrá"><button>Start as Gaeilge</button></a>
            </main>
        </div>
        <footer>
          <p>Copyright ©2019 <a href="https://irishimbasbooks.com">Irish Imbas Books</a></p>
        </footer>
      </>
    )
}

export default IndexPage

function toggleAudio() {
    const backgroundAudio = document.getElementById("introAudio")
    const playButton = document.getElementById("play-button")
    const muteButton = document.getElementById("mute-button")
    if (backgroundAudio.paused) {
        backgroundAudio.play() 
        muteButton.style.display = "block"
        playButton.style.display = "none"
    } else {
        backgroundAudio.pause()
        muteButton.style.display = "none"
        playButton.style.display = "block"
    }
}

export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
    html
    frontmatter {
      title
    }
  }
}
`